import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import {
  addQueryParamWithoutRedirecting,
  removeQueryParam,
  splitTetheredName,
} from '../../../util/DataUtil';
import { Each } from '../../../util/Each';

const ResourceSearch = dynamic(() => import('../../resources/resource-search'));

const MapFilter = ({
  hideFilter,
  data,
  title,
  search,
  triggerFilter,
  onSearchChange,
  filterProps,
  searchQuery,
  screenWidth,
  clearAll,
  languageFilter,
  setLanguageFilter,
  setIsFilterActive,
  setIsSearchActive,
  isFilterActive,
  isSearchActive,
  clearQuery,
  router,
  component,
  cancelText,
  applyFilterText,
  clearAllText,
  selectedText,
  seeMoreText,
  seeLessText,
  toggleText,
  onToggleChange,
  isToggled,
  tooltipText,
  setTetheredFilters,
  pageType,
  avaliableClouds,
  firstLoad,
  handleToggleChangeOnFirstLoad,
}) => {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [seeMore, setSeeMore] = useState([]);
  const [advancedFilter, setAdvancedFilters] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (!isRemoved || isToggled) {
      handleToggleChange();
    }
  }, [filterProps, isToggled]);

  useEffect(() => {
    if (advancedOpen) {
      setAdvancedOpen(!advancedOpen);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (setTetheredFilters !== undefined) {
      setTetheredFilters(advancedFilter);
    }
  }, [advancedFilter]);

  if (firstLoad) {
    handleToggleChangeOnFirstLoad();
  }

  const renderSelectedFilters = () => {
    return (
      <Each
        of={splitTetheredName(advancedFilter)}
        render={(item, index) => (
          <div key={index} className={'selected-category'}>
            <div>
              {item?.baseName}
              {item?.tag !== undefined ? (
                <span className="tethered-font">({item?.tag})</span>
              ) : (
                ''
              )}
            </div>
            <span
              onClick={() => {
                removeFilter(undefined, item.tid, item);
                removeQueryParam(router, item?.baseName);
              }}
              className={'escape'}
            >
              ✕
            </span>
          </div>
        )}
      />
    );
  };

  const renderParents = (data) => {
    let send = [];

    if (!data || !data.length) return;

    data.forEach((parent) => {
      send.push(
        <div className={'parent-container'} key={parent.id}>
          <span className={'parent-name'}>{parent?.name}</span>
          <div className={'horizontal-line'} />
          <div
            className={
              isIncludedMore(parent?.id)
                ? 'children-container active'
                : 'children-container'
            }
          >
            {renderChildren(parent, parent?.children)}
          </div>
          {doesParentHaveMoreThanAllowed(parent?.id) && (
            <div
              onClick={() => handleSeeMore(parent?.id)}
              className={seeMore.includes(parent?.id) ? 'see-less' : 'see-more'}
            >
              {seeMore.includes(parent?.id) ? seeLessText : seeMoreText}
            </div>
          )}
        </div>
      );
    });

    return send;
  };

  const doesParentHaveMoreThanAllowed = (id) => {
    return (
      data.filter((item) => item?.id === id && item?.children?.length > 8)
        .length > 0
    );
  };

  const handleSeeMore = (id) => {
    let more = Object.assign([], seeMore);
    let isIn = isIncludedMore(id);

    if (isIn) {
      removeMore(more, id);
      return;
    }

    more.push(id);
    setSeeMore(more);
  };

  const isIncluded = (id) => {
    return advancedFilter.filter((item) => item?.tid === id).length;
  };

  const isIncludedMore = (id) => {
    return seeMore.filter((item) => item === id).length;
  };

  const removeMore = (more, id) => {
    if (!more) {
      more = Object.assign([], seeMore);
    }

    let removeIndex = more.map((item) => item).indexOf(id);
    more.splice(removeIndex, 1);
    setSeeMore(more);
  };

  const handleToggleChange = () => {
    if (isToggled) {
      const combinedArray = [
        ...advancedFilter,
        ...advancedFilter
          .filter((obj) => !obj.name.includes('(T)'))
          .filter((obj) => !obj?.vid)
          .map((obj) => ({ name: obj?.name + '(T)', tid: obj?.tid })),
      ];

      const uniqueCombinedArray = Array.from(
        new Set(combinedArray.map(JSON.stringify)),
        JSON.parse
      );
      setAdvancedFilters(uniqueCombinedArray);
    } else {
      const filteredArray = advancedFilter.filter(
        (obj) => !obj?.name.includes('(T)')
      );
      setAdvancedFilters(
        filteredArray.length === 0 ? filterProps : filteredArray
      );
    }
  };

  const handleSelect = (parent, child) => {
    let advanced = Object.assign([], advancedFilter);
    let isIn = isIncluded(child?.tid);
    let url = '';
    let uniqueCombinedArray = [];

    if (typeof window !== undefined) {
      url = window.location.pathname;
    }

    if (isIn) {
      removeFilter(advanced, child?.tid, child);
      return;
    }

    if (parent?.description.length !== 0) {
      setLanguageFilter([...languageFilter, child]);
    }

    if (
      component === 'global' ||
      (component === 'region' && child?.vid === undefined)
    ) {
      addQueryParamWithoutRedirecting(router, url, child?.name, 'cloud');
    }

    if (component === 'region' && child?.vid !== undefined) {
      addQueryParamWithoutRedirecting(
        router,
        url,
        child?.name,
        'certification'
      );
    }

    advanced.push(child);

    if (isToggled) {
      const combinedArray = [
        ...advanced,
        ...advanced
          .filter((obj) => !obj.name.includes('(T)'))
          .filter((obj) => !obj?.vid)
          .map((obj) => ({ name: obj?.name + '(T)', tid: obj?.tid })),
      ];

      uniqueCombinedArray = Array.from(
        new Set(combinedArray.map(JSON.stringify)),
        JSON.parse
      );
      setAdvancedFilters(uniqueCombinedArray);
    } else {
      setAdvancedFilters(advanced);
    }

    setIsSearchActive(false);
    setIsFilterActive(true);
    setIsRemoved(false);
  };

  const handleApplyFilters = () => {
    setAdvancedOpen(!advancedOpen);
    // setAdvancedFilters(advanced);
    setIsSearchActive(false);
    setIsFilterActive(true);
    // triggerFilter(advanced);
    triggerFilter(advancedFilter);
    setIsRemoved(false);
  };

  const removeFilter = (advanced, childId, child) => {
    if (!advanced) {
      advanced = Object.assign([], advancedFilter);
    }

    // let removeIndex = advanced.map(item => item?.tid).indexOf(childId);
    // // let removeIndex = advanced.map(item => item.name).indexOf(child.name);

    // advanced.splice(removeIndex, 1);

    advanced = advanced.filter((item) => item?.tid !== childId);

    setAdvancedFilters(advanced);
    triggerFilter(advanced);
    setIsRemoved(true);

    // if(advanced.length === 0){
    removeQueryParam(router, child);
    // }
  };

  const renderChildren = (parent, children) => {
    if (!children) {
      return;
    }

    let send = [];

    children.forEach((child) => {
      send.push(
        child.name && (
          <div
            key={child.tid}
            className={isIncluded(child.tid) ? 'child active' : 'child'}
            onClick={() => handleSelect(parent, child)}
          >
            <span>{child.name}</span>
            <span
              onClick={() => {
                removeFilter(undefined, child.tid, child);
                removeQueryParam(router, child?.baseName);
              }}
              className={isIncluded(child.tid) ? 'escape active' : 'escape'}
            >
              ✕
            </span>
          </div>
        )
      );
    });

    return send;
  };

  const handleHorizontalScroll = () => {
    if (typeof window === 'undefined') {
      return;
    }

    let container = document.querySelector('.selected-categories');
    const first = document.querySelector(
      '.selected-categories .selected-category'
    );

    if (!first) {
      return;
    }

    if (isElementInViewport(first)) {
      container.appendChild(first);
      container.scrollTo(container.scrollLeft - first.offsetWidth, 0);
    }
  };

  const isElementInViewport = (el) => {
    let rect = el.getBoundingClientRect();
    return rect.right > 0;
  };

  const renderFilterResourcesDropdown = () => {
    if (screenWidth < 1024) {
      return (
        <>
          {advancedOpen > 0 && <div className={'help-black'} />}
          <img src={'/images/filter-icon.svg'} alt={'filter'} />
        </>
      );
    }
    return (
      <div>
        <span>{title}</span>
        {advancedOpen > 0 && <div className={'help-black'} />}
        <img src={'/images/arrow-down.svg'} alt={'arrow'} />
      </div>
    );
  };

  const getInputValueClass = () => {
    return searchQuery.length !== 0 ? ' focus' : ' ';
  };

  const handleOnChange = () => {
    onToggleChange();
  };

  const renderAvailableCloudOnRampsFilters = (data) => {
    let send = [];

    if (!data || !data.length) {
      return;
    }

    data.forEach((parent) => {
      send.push(
        <div className={'parent-container'} key={parent.id}>
          <span className={'parent-name'}>{parent?.name}</span>
          <div className={'horizontal-line'} />
          <div
            className={
              isIncludedMore(parent?.id)
                ? 'children-container active'
                : 'children-container'
            }
          >
            {renderChildren(parent, parent?.children)}
          </div>
          {doesParentHaveMoreThanAllowed(parent?.id) && (
            <div
              onClick={() => handleSeeMore(parent?.id)}
              className={seeMore.includes(parent?.id) ? 'see-less' : 'see-more'}
            >
              {seeMore.includes(parent?.id) ? seeLessText : seeMoreText}
            </div>
          )}
        </div>
      );
    });

    return send;
  };

  const renderToggle = () => {
    return (
      <div className={'inner-container'}>
        <div className={'tethered-toggle'}>
          <div className={'toggle-text'}>
            <p>{toggleText}</p>
            <div className={'tooltip-wrapper'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <g clipPath="url(#clip0_25766_9729)">
                  <path
                    d="M6.34375 3.71875H7.65625V5.03125H6.34375V3.71875ZM6.34375 6.34375H7.65625V10.2812H6.34375V6.34375ZM7 0.4375C3.3775 0.4375 0.4375 3.3775 0.4375 7C0.4375 10.6225 3.3775 13.5625 7 13.5625C10.6225 13.5625 13.5625 10.6225 13.5625 7C13.5625 3.3775 10.6225 0.4375 7 0.4375ZM7 12.25C4.10594 12.25 1.75 9.89406 1.75 7C1.75 4.10594 4.10594 1.75 7 1.75C9.89406 1.75 12.25 4.10594 12.25 7C12.25 9.89406 9.89406 12.25 7 12.25Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_25766_9729">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span
                className={isShown ? 'tooltip-text show' : 'tooltip-text hide'}
              >
                {tooltipText}
              </span>
            </div>
          </div>
          <div className={'toggle'}>
            <label className="switch">
              <input
                checked={isToggled}
                onChange={() => handleOnChange()}
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'map-filter'}>
      <div className={'base-filter'}>
        <div className={'inner-container'}>
          <div className={'filter-container flex relative'}>
            {!hideFilter && (
              <div
                className={advancedOpen ? 'left active' : 'left'}
                onClick={() => {
                  setAdvancedOpen(!advancedOpen);
                  setIsSearchActive(false);
                  setIsFilterActive(true);
                }}
              >
                {renderFilterResourcesDropdown()}
              </div>
            )}
            <div className={'right'}>
              <ResourceSearch
                setIsFilterActive={setIsFilterActive}
                setIsSearchActive={setIsSearchActive}
                hideFilter={hideFilter}
                placeholder={search}
                onSearchChange={onSearchChange}
                searchQuery={searchQuery}
                clearAll={clearAll}
              />
              {advancedFilter.length > 0 && (
                <div className={'selected-container'}>
                  {<span className={'selected'}>{selectedText}</span>}
                  <div className={'selected-categories'}>
                    {renderSelectedFilters()}
                  </div>
                  {<div className={'selected-categories-box-shadow'} />}

                  <div
                    className={`clear-all-container ${isSearchActive && !isFilterActive ? 'not-active' : ''}`}
                  >
                    {advancedFilter.length > 1 && (
                      <img
                        className={'scroll-arrow'}
                        src={'/images/arrow-right.svg'}
                        onClick={() => handleHorizontalScroll()}
                        alt={'scroll-arrow'}
                      />
                    )}
                    {advancedFilter.length > 0 && (
                      <span
                        onClick={() => {
                          setAdvancedFilters([]);
                          triggerFilter([]);
                          setLanguageFilter([]);
                          removeQueryParam(router);
                          if (isToggled) {
                            onToggleChange();
                          }
                        }}
                        className={'clear'}
                      >
                        {clearAllText}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <img
              className={`close-icon  ${getInputValueClass()} ${!isSearchActive && isFilterActive ? 'not-active' : ''}`}
              src={'/images/close-icon.svg'}
              alt={'close'}
              onClick={clearQuery}
            />
          </div>
        </div>
      </div>
      <div
        className={advancedOpen ? 'advanced-filter active' : 'advanced-filter'}
      >
        <div className={'inner-container'}>
          <div
            className={
              pageType === 'regional-map'
                ? 'advanced-filter-container regional-map-filters'
                : 'advanced-filter-container'
            }
          >
            {/* TETHERED ACCESS - REMOVE FOR PRODUCTION DEPLOY */}
            {avaliableClouds !== undefined && (
              <div className="toggle-wrapper">
                {renderAvailableCloudOnRampsFilters(avaliableClouds)}
                {pageType === 'regional-map' && renderToggle()}
              </div>
            )}
            {/* TETHERED ACCESS - REMOVE FOR PRODUCTION DEPLOY */}
            {renderParents(data)}
          </div>
        </div>
        {/* TETHERED ACCESS - REMOVE FOR PRODUCTION DEPLOY */}
        {pageType === 'global-map' && renderToggle()}
        {/* TETHERED ACCESS - REMOVE FOR PRODUCTION DEPLOY */}
        <div className={'horizontal-line'} />
        <div className={'inner-container'}>
          <div className={'advanced-filter-buttons'}>
            <div
              className={'cancel'}
              onClick={() => {
                setAdvancedOpen(!advancedOpen);
              }}
            >
              {cancelText}
            </div>
            <div
              className={'apply-filter'}
              onClick={() => {
                handleApplyFilters();
              }}
            >
              {applyFilterText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapFilter;
